import React, { useEffect } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'

import {
  VITALIVE_SERVER_URL,
  xHeaderClientType,
  showJudgeMeInAbout,
} from '../app/constants/constants'

import { CustomerDataContext } from '../app/contexts/CustomerDataContext'
import { getCustomerData } from '../app/utilities/getCustomerData'
import axios from 'axios'
import { selectedSkus } from '../app/components/About/staticData'
import { useCart } from '../app/customHooks/useCart'
import { useRouter } from 'next/router'
import stylesHero from '../app/components/About/Hero/Hero.module.scss'
import styles from '../app/components/Home/Steps/Steps.module.scss'
import {
  getDiscountPrice,
  getProductsWithNewPrice,
} from '../app/utilities/getDiscountPrice'
import {reviewsData} from '../app/components/VitalivClub/staticData'

const LayoutWithReviews = dynamic(() =>
  import('../app/components/Layouts/LayoutWithReviews')
)
const Hero = dynamic(() => import('../app/components/About/Hero/Hero'))
const MaxBenefit = dynamic(() =>
  import('../app/components/Home/MaxBenefit/MaxBenefit')
)
const ProductsSlider = dynamic(() =>
  import('../app/components/Home/ProductsSlider/ProductsSlider')
)
const PayQualityBlock = dynamic(() =>
  import('../app/components/Home/PayQualityBlock/PayQualityBlock')
)
const Goals = dynamic(() => import('../app/components/Home/Goals/Goals'))

const Steps = dynamic(() => import('../app/components/Home/Steps/Steps'))
const BannerBlock = dynamic(() =>
  import('../app/components/Home/BannerBlock/BannerBlock')
)
const VideoBlock = dynamic(() =>
  import('../app/components/Home/VideoBlock/VideoBlock')
)
const FaqBlock = dynamic(() =>
  import('../app/components/Home/FaqBlock/FaqBlock')
)

const JudgeMe = dynamic(() =>
  import('../app/components/common/JudgeMe/JudgeMe')
)

const Reviews = dynamic(() =>
    import('../app/components/VitalivClub/Reviews/Reviews')
)

const News = dynamic(() => import('../app/components/Home/News/News'))

const Home = ({
  customerData,
  isWebView,
  productList,
  categories,
  showReviews,
  coefficientDiscount,
}) => {
  const { cartContent, setShopifyCart, reconciliation, removeProduct } =
    useCart(customerData.email)

  const router = useRouter()

  const { joinclub } = router.query
  const { about } = router.query
  useEffect(() => {
    if (joinclub) {
      const target = document.getElementById(`maxBenefit`)

      if (target) {
        window.scrollTo({
          top: target.offsetTop - document.documentElement.clientHeight / 8,
          behavior: 'smooth',
        })
      }
    }

    if (about) {
      const target = document.getElementById(`temporary-About`)
      if (target) {
        window.scrollTo({
          top:
            target.offsetTop - document.documentElement.clientHeight / 8 + 100,
          behavior: 'smooth',
        })
      }
    }
  }, [router.query])

  return (
    <CustomerDataContext.Provider value={customerData}>
      <LayoutWithReviews
        currentPage="index"
        isWebView={isWebView}
        isMobileMenu={true}
        showReviews={showReviews}
      >
        <Hero
          link="maxBenefit"
          text={
            <>
              <p>
                to get the maximum <span>benefit</span> from food supplements
              </p>
            </>
          }
          stylesText={stylesHero.heroMainText}
        />
        <MaxBenefit
          //animation="fade-right"
          containerScaling={stylesHero.scalingTwo}
        />
        {/*<PayQualityBlock*/}
        {/*  title="Pay only for quality"*/}
        {/*  subtitle={*/}
        {/*    <>*/}
        {/*      Cost without <br />*/}
        {/*      <b>mark-up</b>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*  animation="fade-zoom-in"*/}
        {/*  oldPrice="20.99"*/}
        {/*  newPrice="10.99"*/}
        {/*  containerScaling={stylesHero.scaling}*/}
        {/*/>*/}
        <ProductsSlider
          productList={productList}
          cartContent={cartContent}
          setShopifyCart={setShopifyCart}
          removeProduct={removeProduct}
          reconciliation={reconciliation}
          containerScaling={stylesHero.scalingTwo}
          coefficientDiscount={coefficientDiscount}
        />
        <Goals
          categories={categories}
          animation="fade-zoom-in"
          containerScaling={stylesHero.scalingTwo}
        />
        <BannerBlock containerScaling={stylesHero.scalingTwo} />
        <Reviews
          data={reviewsData}
          button="More reviews (10)"
          marginTop={true}
          title={
            <>
              Top Customer <b>Reviews</b>
            </>
          }
          text2="Over 200,000+ happy customers"
          stylesTitle={true}
          containerScaling={stylesHero.scalingTwo}
        />
        {/*{showReviews ? <JudgeMe renderBlock="allReviews" /> : null}*/}

        <Steps
            title={
              <div className={styles.mainHeadline}>
                How do you become a <b>Vitaliv Club member</b>?
              </div>
            }
            subtitle={''}
            button="More about Vitaliv Club"
            buttonLink="/vitalivclub"
            //animation="fade-up"
            icon1="/images/aboutUs/step-icon1-1.svg"
            icon2="/images/aboutUs/step-icon2-1.svg"
            icon3="/images/aboutUs/step-icon3-1.svg"
            containerScaling={stylesHero.scalingTwo}
            clubText={true}
            indexStyles={true}
        />

        <VideoBlock containerScaling={stylesHero.scalingTwo} />
        <FaqBlock containerScaling={stylesHero.scalingTwo} />
        <News containerScaling={stylesHero.scalingTwo} />
      </LayoutWithReviews>
    </CustomerDataContext.Provider>
  )
}

export async function getServerSideProps(ctx) {
  const customerData = await getCustomerData(ctx.req.cookies)
  const coefficientDiscount = await getDiscountPrice(ctx)

  let responseProducts = await axios
    .get(`${VITALIVE_SERVER_URL || ''}/product?status=active`)
    .then((res) => res.data?.result)

  responseProducts = await getProductsWithNewPrice(
    responseProducts,
    coefficientDiscount,
    ctx
  )

  const selectedProductList = responseProducts?.filter((item) =>
    selectedSkus.includes(item.sku)
  )

  const responseCollection = await axios
    .get(`${VITALIVE_SERVER_URL}/collection?type=category`)
    .then((res) => res.data.result)
    .catch((e) => console.log(e))

  const categories = responseCollection?.slice(0, 10)

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, [
        'common',
        'header',
        'footer',
      ])),
      customerData,
      categories,
      productList: selectedProductList,
      coefficientDiscount: coefficientDiscount,
      isWebView: ctx.req.headers['x-client-type']
        ? ctx.req.headers['x-client-type'] === xHeaderClientType
        : false,
      nonZendesk: true,
      showReviews: showJudgeMeInAbout,
    },
  }
}

export default Home

export const goalsHealthData = [
    {
        id: 1,
        src: '/images/VitalivClub/icon1.svg'
    },
    {
        id: 2,
        src: '/images/VitalivClub/icon2.svg'
    },
    {
        id: 3,
        src: '/images/VitalivClub/icon3.svg'
    },
    {
        id: 4,
        src: '/images/VitalivClub/icon4.svg'
    }
]

export const approachData = [
    {
        id: 1,
        src: '/images/VitalivClub/icon5.svg',
        title: 'Advanced production technologies',
        text: 'We work with only the best manufacturers and use advanced production technologies.'
    },
    {
        id: 2,
        src: '/images/VitalivClub/icon6.svg',
        title: 'Tested formulas and carefully ingredients',
        text: 'Our experienced nutritionists and manufacturing experts ensure that only tested formulas and carefully selected specialized ingredients are used.'
    },
    {
        id: 3,
        src: '/images/VitalivClub/icon7.svg',
        title: 'Quality control',
        text: 'Strict compliance controls and the highest international quality standards are used in all stages of production.'
    },
]

export const motivationImgData = [
    {
        id: 1,
        src: '/images/VitalivClub/icon8.svg',
        text: 'Unobtrusive Reminders'
    },
    {
        id: 2,
        src: '/images/VitalivClub/icon9.svg',
        text: 'Refill Supplies in 1 Click'
    },
    {
        id: 3,
        src: '/images/VitalivClub/icon10.svg',
        text: 'Progress Tracking'
    }
]

export const reviewsData = [
    {
        id: 1,
        srcUser: '/images/VitalivClub/Tina.jpg',
        text: 'I\'ve been searching for a supplement that supports my joints for years. Shouldn\'t high-quality products be expensive? Not at Vitaliv Club! With Vitaliv Club, I can quickly and easily order the best dietary supplements.They offer tremendous value. Quality is their number one concern. Vitaliv\'s Turmeric & Ginger is a fantastic product! Last month, I added Joint Support with Glucosamine to my order. Details like these influence my choice of where to spend my money.',
        date: 'March 29, 2022',
        name: 'Tina',
        photo: {
            0: '/images/VitalivClub/1-2.webp',
            1: '/images/VitalivClub/1-1.webp',
        }
    },
    {
        id: 2,
        srcUser: '/images/VitalivClub/user1.svg',
        text: 'My sister told me about the Vitaliv Club. I decided to give it a try after they offered a one month free trial. My first choice was Sleep Support and 5-HTP. Before, there were many nights I found it difficult to fall asleep and often couldn’t get back to sleep due to the daily stress at work. However, now I sleep like a baby at night! This couple is now part of my evening routine. I have healthy biorhythms again. Now that I\'m a member, I\'m excited to try more Vitaliv products. Already ordered CoQ10 to test.',
        date: 'March 25, 2022',
        name: 'Shelli',
        photo: {
            0: '/images/VitalivClub/2-1.webp',
            1: '/images/VitalivClub/2-2.webp',
        }
    },
    {
        id: 3,
        srcUser: '/images/VitalivClub/Bernard.jpg',
        text: 'Since I heard so many positive things about Vitaliv Club and its health benefits, I started taking Turmeric & Ginger from Vitaliv. Since I had a lot of joint pain, I chose the product with turmeric for joint health. It was not surprising to see improvements in my joints and back. In addition to my brain and memory becoming sharper, my digestion and metabolism have also improved. I am feeling more energized than ever! No matter what, I\'ll continue to take it and recommend it to friends and family.',
        date: 'March 17, 2022',
        name: 'Bernard',
        photo: {
            0: '/images/VitalivClub/3-1.webp',
            1: '/images/VitalivClub/3-2.jpg',
        }
    },
    {
        id: 'reviews',
        srcUser: '/images/VitalivClub/Kim.jpg',
        text: 'I have been a member of the Vitaliv Club for 3 months now. And for me, as a lover of food supplements, this is a real find. I buy supplements for myself and my family at low prices. And the quality is excellent—I have something to compare :)  I advise all lovers of supps to try. Plus there is a free 30 days.',
        date: 'March 13, 2022',
        name: 'Kim'
    },
    {
        id: 5,
        srcUser: '/images/VitalivClub/Thomas.jpg',
        text: 'Good supplements at great prices. Free trial for 30 days. First I bought Omega 3, D3 and probiotics. The pack arrived quickly, neatly packaged. There was no automatic subscription, but I decided to buy a Vitalic Club membership for a year, because I want to try the rest of the range. Recommend.',
        date: 'March 03, 2022',
        name: 'Thomas'
    },
    {
        id: 6,
        srcUser: '/images/VitalivClub/Ostin.jpg',
        text: 'I love Vitaliv Club! I’m very satisfied with the supplements and service they provide. And it also saves me money. Vitaliv’s product line includes everything my body needs to support my health goals.',
        date: 'February 26, 2022',
        name: 'Ostin'
    },
    {
        id: 7,
        srcUser: '/images/VitalivClub/Courtney1.jpg',
        text: 'I suffer from insomnia and was looking for quality supplements for myself, but not very expensive. In the Vitaliv Club I found Sleep Support - herbal blend with Melatonin. And just for $4.20. I started taking it and falling asleep became much easier. In the morning I feel energized and rested. When the free 30 days are over, I will definitely buy myself a membership for 1 year.',
        date: 'May 24, 2022',
        name: 'Courtney',
        photo: {
            0: '/images/VitalivClub/7-1.jpeg',
        }
    },
    {
        id: 8,
        srcUser: '/images/VitalivClub/Amanda.jpg',
        text: '5 stars for this service! I used the 30-day free trial and bought myself a bunch of products for just $26! Delivery was free and fast. About 3-4 days.There is no automatic subscription to anything. And it\'s nice. I\'ll buy myself a full membership if I want to.',
        date: 'February 19, 2022',
        name: 'Amanda'
    },
    {
        id: 9,
        srcUser: '/images/VitalivClub/user1.svg',
        text: 'Turmeric & Ginger by Vitaliv helps me protect my joints, especially my knees, at the gym. I highly recommend this to every athlete and person who has any type of joint discomfort or pain.\n' +
            'After I finished the Turmeric, I bought a bundle of 5 products. Just what I need for my knees… Calcium, Glucosamine, Vitamins D, and С for only $20... Good deal ;)\n',
        date: 'February 14, 2022',
        name: 'Nicholas',
        photo: {
            0: '/images/VitalivClub/9-1.jpg',
            1: '/images/VitalivClub/9-2.jpg',
            2: '/images/VitalivClub/9-3.jpg',
            3: '/images/VitalivClub/9-4.jpg',
            4: '/images/VitalivClub/9-5.jpg'
        }
    },
    {
        id: 10,
        srcUser: '/images/VitalivClub/A.jpg',
        text: 'Woooow! Super prices! Best ever health club.',
        date: 'February 10, 2022',
        name: 'A.'
    },
    {
        id: 11,
        srcUser: '/images/VitalivClub/Gloria.jpg',
        text: 'Great products and great customer service.',
        date: 'February 09, 2022',
        name: 'Gloria'
    },
    {
        id: 12,
        srcUser: '/images/VitalivClub/Sabina.jpg',
        text: 'I have never seen supplements sold without a markup. I tried it and was pleasantly surprised. I especially appreciate the opportunity to buy whatever supplements I want without extra charges and without paying for the club for the first month. Then, if you want to enjoy the benefits for 1 year - buy a membership in 1 click. At first I doubted, but then I decided that it was only 4 dollars per month. Cheaper than any quality drugstore supplement. I’m glad I made this decision.',
        date: 'February 05, 2022',
        name: 'Sabina'
    },
    {
        id: 13,
        srcUser: '/images/VitalivClub/Christian.jpg',
        text: 'Food supplements without extra charge - an interesting idea. I bought a few products to try, and they just arrived three days later. So far so good. I will try it later. I will write what I think.',
        date: 'February 05, 2022',
        name: 'Christian'
    },
    {
        id: 14,
        srcUser: '/images/VitalivClub/Rosa.jpg',
        text: 'Usually I wait for delivery for 7 days, but here my vitamins came in 3 days. Good)',
        date: 'February 03, 2022',
        name: 'Rosa'
    },
    {
        id: 15,
        srcUser: '/images/VitalivClub/Ann.jpg',
        text: 'While my free 30 days membership is valid, I managed to try Omega 3 and Probiotics. Omega 3 without fishy burp and fishy taste and easy to swallow. Probiotics attracted me to the Maktrek® Bi-Pass technology - I heard about it.',
        date: 'February 02, 2022',
        name: 'Ann'
    },
]